import { SelectableWithIdObject } from "src/components/Form/types";
import request from "./request";

interface CourseCategoryResponse {
  id: number;
  courseCategory: string;
}
export class CourseCategory extends SelectableWithIdObject {
  public courseCategory: string;

  constructor(data: CourseCategoryResponse) {
    super(data.id);
    this.courseCategory = data.courseCategory;
  }

  getDisplayName(): string {
    return this.courseCategory;
  }
}

export const mapCourseCategory = (
  data: CourseCategoryResponse,
): CourseCategory => {
  return new CourseCategory(data);
};

export const getcourseCategories = async (): Promise<CourseCategory[]> => {
  const response = await request(`/course-category`);
  return response.map((data: CourseCategoryResponse) =>
    mapCourseCategory(data),
  );
};
