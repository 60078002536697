export interface Model {
  validate: () => boolean;
}

export abstract class SelectableObject {
  abstract getDisplayName(): string;

  abstract getId(): number | string;

  isEqual(id: number | string): boolean {
    return this.getId() === id;
  }
}

export abstract class SelectableWithIdObject extends SelectableObject {
  id: number;

  protected constructor(id: number) {
    super();
    this.id = id;
  }

  getId(): number | string {
    return this.id;
  }
}

export const createSelectableObject = (
  id: number | string,
  name: string,
): SelectableObject => ({
  getId: () => id,
  getDisplayName: () => name,
  isEqual(id: number | string): boolean {
    return this.getId() === id;
  },
});