import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import CourseRemainingDaysIcon from "../CourseRemainingDaysIcon/index";

interface CourseStatusTooltipData {
  status: string;
  days?: number;
  validUntil?: string;
  buffer?: string;
}

interface CourseStatusTooltipProps {
  data: CourseStatusTooltipData;
}

const CourseStatusTooltip: React.FC<CourseStatusTooltipProps> = ({ data }) => {
  const [hoveredStatus, setHoveredStatus] =
    useState<CourseStatusTooltipData | null>(null);
  const [debouncedStatus, cancelDebounce] = useDebounce(hoveredStatus, 200);

  useEffect(() => {
    if (debouncedStatus !== null) {
      setHoveredStatus(debouncedStatus);
    }
  }, [debouncedStatus]);

  const handleMouseEnter = () => {
    if (data.validUntil) {
      setHoveredStatus({
        validUntil: data.validUntil,
        buffer: data.buffer,
        status: data.status,
        days: data.days,
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredStatus(null);
  };

  const CourseStatusTooltipStyle: React.CSSProperties = {
    visibility: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    color: "#fff",
    textAlign: "center",
    borderRadius: "4px",
    padding: "5px",
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 0,
    width: "100px",
    transition: "opacity 0.3s ease-out",
    zIndex: 1100,
  };

  const statusContainerHoverStyle: React.CSSProperties = {
    visibility: "visible",
    opacity: 1,
  };

  return (
    <div
      className="status-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <CourseRemainingDaysIcon status={data.status} days={data.days} />
      {debouncedStatus && (
        <div
          className="status-CourseStatusTooltip"
          style={{
            ...CourseStatusTooltipStyle,
            ...(debouncedStatus && { ...statusContainerHoverStyle }),
          }}
        >
          {debouncedStatus.buffer ? (
            <>
              Expire date: {debouncedStatus.validUntil}, Buffer zone:{" "}
              {debouncedStatus.buffer}
            </>
          ) : (
            debouncedStatus.validUntil
          )}
        </div>
      )}
    </div>
  );
};

export default CourseStatusTooltip;
