import { COURSE_STATUS } from "../../constants";
import CourseService from "../../services/course";
import CertificateService, * as certificate from "../../services/certificate";

const userMatchOperation = (user, operation) => {
  const courses = [];

  for (const course of operation.courses) {
    courses.push(certificate.userCourseMatch(course, user));
  }

  for (const customCourse of operation.customCourses) {
    courses.push(
      CertificateService.userCustomCertificateMatch(customCourse, user),
    );
  }

  for (const grp of operation.groups) {
    courses.push(certificate.userGroups(grp, user));
  }

  return courses;
};

const isNotOk = (course) =>
  course.status === COURSE_STATUS.MISSING ||
  course.status === COURSE_STATUS.EXPIRED;

const isBuffer = (course) => course.status === COURSE_STATUS.BUFFER;

const operationStatus = (courses) => {
  if (courses.some(isNotOk)) {
    return COURSE_STATUS.MISSING;
  }
  if (courses.some(isBuffer)) return COURSE_STATUS.BUFFER;
  return COURSE_STATUS.OK;
};

export const getUserOperationsDescription = (user, crew) => {
  const crewOperations = crew.reduce((acc, category) => [...acc, category], []);
  const mappedUser = certificate.mapUserCertificates(user);

  const operations = [];
  // get only operations
  const userOperations = user.assignedRoles.filter(
    (op) => !!op.position_id && !!op.operation_id,
  );

  for (const operation of userOperations) {
    const crewOperation = crewOperations.find(
      (co) =>
        co.position_id === operation.position_id &&
        co.operation_id === operation.operation_id,
    );

    if (crewOperation) {
      const courses = userMatchOperation(mappedUser, crewOperation);

      operations.push({
        category_name: crewOperation.category_name,
        position_name: crewOperation.position_name,
        operation_name: crewOperation.operation_name,
        position_id: crewOperation.position_id,
        operation_id: crewOperation.operation_id,
        category_id: crewOperation.category_id,
        status: operationStatus(courses),
        courses,
      });
    }
  }

  const sorted = operations.sort(
    (a, b) =>
      a.category_id - b.category_id ||
      a.position_id - b.position_id ||
      a.operation_id - b.operation_id,
  );

  return {
    user_id: user.user_id,
    name: user.name,
    email: user.email,
    certificates: sorted,
  };
};

const toDisplayObject = (certificates, metadata) => (operation) => {
  const title = `${
    metadata.categories.find((category) => category.id === operation.categoryId)
      .name
  }  →  ${
    metadata.positions.find((pos) => pos.id === operation.positionId).name
  }  →  ${
    metadata.operations.find((ope) => ope.id === operation.operationId).name
  }`;
  const certificatesRet = [];

  for (const course of operation.courses) {
    const matched = CertificateService.mapToCourse(course, certificates);
    certificatesRet.push(matched);
  }

  for (const customCourse of operation.customCourses) {
    const matched = CertificateService.mapToCustomCourse(
      customCourse,
      certificates,
    );
    certificatesRet.push(matched);
  }

  for (const group of operation.groups) {
    const matched = CertificateService.mapToGroup(group, certificates);
    certificatesRet.push(matched);
  }

  return {
    title,
    id: `${operation.categoryId}-${operation.positionId}-${operation.operationId}`,
    status: CertificateService.groupStatus(certificatesRet),
    certificates: certificatesRet,
  };
};

const byOperation = (categoryId, positionId, operationId) => (course) => {
  if (CourseService.isAircraftCourse(course) || course.hidden) {
    return false;
  }

  return (
    (course.operationId === operationId || !course.operationId) &&
    (course.positionId === positionId || !course.positionId) &&
    course.categoryId === categoryId
  );
};

const OperationsService = {
  getOperations(properties, courses, customCourses, groups) {
    const operations = {};
    properties.forEach((property) => {
      if (property.operationId) {
        const categoryId = property.categoryId;
        const positionId = property.positionId;
        const operationId = property.operationId;
        const id = `${categoryId}-${positionId}-${operationId}`;
        const fn = byOperation(categoryId, positionId, operationId);
        if (!operations[`${id}`]) {
          operations[id] = {
            categoryId: categoryId,
            positionId: positionId,
            operationId: operationId,
            courses: courses.filter(fn).reduce(CourseService.deduplicate, []),
            customCourses: customCourses
              .filter(fn)
              .reduce(CourseService.deduplicate, []),
            groups: groups
              .filter(fn)
              .reduce(CourseService.groupDeduplicate, []),
          };
        }
      }
    });
    return Object.values(operations).sort(CourseService.sortCourses);
  },
  toDisplayObject(operations, certificates, metadata) {
    return operations.map(toDisplayObject(certificates, metadata));
  },
};

export default OperationsService;
