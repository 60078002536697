import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";

interface SkeletonTableLoaderProps {
  columns?: number;
  rows?: number;
  cellHeight?: number;
  headerHeight?: number;
}

const SkeletonTableLoader: React.FC<SkeletonTableLoaderProps> = ({
  columns = 30,
  rows = 30,
  cellHeight = 32,
  headerHeight = 40,
}) => {
  return (
    <Table aria-label="Loading table skeleton, showing placeholders for data">
      <TableHead>
        <TableRow>
          {Array.from({ length: columns }).map((_, index) => (
            <TableCell
              key={index}
              aria-label={`Header column ${index + 1}`}
              style={{ height: headerHeight }}
            >
              <Skeleton variant="text" width="80%" height={24} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <TableRow key={rowIndex} aria-rowindex={rowIndex + 1}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <TableCell key={colIndex} aria-colindex={colIndex + 1}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={cellHeight}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SkeletonTableLoader;
