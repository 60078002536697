import React from "react";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../components/Button";

import { StyledSwitch } from "../../components/Base";

const MoodleCourseItem = ({
  onEdit,
  onVisibleChange,
  onShowChange,
  course,
}) => {
  const refreshersList = course.refreshers ? (
    <ul>
      {course.refreshers.map((course) => (
        <li key={course.id}>
          ({course.id}) {course.name}
        </li>
      ))}
    </ul>
  ) : null;
  function timestampToStringDate(timestamp) {
    if (!timestamp) return "-";
    return moment.unix(timestamp).utc().format("DD.MM.YYYY");
  }
  return (
    <TableRow>
      <TableCell>{course.id}</TableCell>
      <TableCell>{course.displayName}</TableCell>
      <TableCell>{course.summary}</TableCell>
      <TableCell>{timestampToStringDate(course.startDate)}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change moodle course visibility"
          checked={!!course.visible}
          onChange={() => onVisibleChange(course.id, course.visible ? 0 : 1)}
        />
      </TableCell>
      <TableCell>
        <StyledSwitch
          title="Change showing at all courses"
          checked={!!course.showAtAllCourses}
          onChange={() =>
            onShowChange(course.id, course.showAtAllCourses ? 0 : 1)
          }
        />
      </TableCell>
      <TableCell>{refreshersList}</TableCell>
      <TableCell>
        {course.isConfigured ? "configured" : "not configured"}
      </TableCell>
      <TableCell>{course.courseCategory}</TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(course.id)}>
          <Button title="Edit Moodle Course" icon={<EditIcon />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default MoodleCourseItem;
