import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import { formatValidPeriod } from "../../services/formatters";

const CustomCourseItem = ({ onEdit, onDelete, onStatusChange, course }) => {
  return (
    <TableRow>
      <TableCell>{course.id}</TableCell>
      <TableCell>{course.name}</TableCell>
      <TableCell>{formatValidPeriod(course.validPeriod)}</TableCell>
      <TableCell>{course.courseCategory}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change custom course status"
          checked={course.status === "active"}
          onChange={() =>
            onStatusChange(
              course.id,
              course.status === "active" ? "passive" : "active",
            )
          }
        />
      </TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(course.id)}>
          <Button title="Edit custom course" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(course.id)}>
          <Button
            title="Delete custom course"
            icon={<DeleteForeverIcon color="error" />}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CustomCourseItem;
