import React, { useEffect, useMemo, useState } from "react";
import { Link as NavigationLink } from "react-router-dom";
import { sum, times } from "ramda";
import { useDebounce } from "use-debounce";
import { mapKeys, camelCase } from "lodash";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CloseIcon from "@mui/icons-material/Close";

import { Paper, Popover, TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

import CourseStatusIcon from "../CourseStatusIcon";
import { formatShortName } from "../../services/formatters";
import { COURSE_STATUS } from "../../constants";

import "./index.scss";
import Button from "../Button";
import Operations from "../../features/operations";
import { getAllCourses } from "../../api/courses";
import { getUserCertificates } from "../../api/user";
import CurrentUserCrewContext from "../../context/CurrentUserCrewContext";
import { getUserBoughtCourses } from "../../api/payment";
import CertificateActions from "../../context/CertificateActions";
import CourseHistory from "../../features/course-history";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar - 3,
    width: "200px",
    "&:first-of-type": {
      zIndex: theme.zIndex.appBar - 1,
    },
  },
  "&.MuiTableCell-body": {
    left: 0,
    backgroundColor: "white",
    position: "sticky",
    zIndex: theme.zIndex.appBar - 2,
  },
}));

const applyOpFilter = ({ searchOp, positions, users }) => {
  // filtering based on searchOp:
  // find in position names and select position and all ops
  // if not: find in operation names and select position and only specific op
  // determine the operation indices to show
  // filter positions & operations
  // users.positions can be filtered in view directly
  // finally sort users according to who has "the most green"
  let opIndexes;
  if (searchOp) {
    const posIxs = {};
    let offset = 0;
    opIndexes = {};
    searchOp = searchOp.toLowerCase();
    for (let posIx = 0; posIx < positions.length; posIx++) {
      const position = positions[posIx];
      let show = false;
      const [{ position_name: p }] = position;
      if (p.toLowerCase().includes(searchOp)) {
        const start = offset;
        for (const ix of times((n) => start + n, position.length)) {
          opIndexes[ix] = true;
        }
        show = true;
      } else {
        for (let opIx = 0; opIx < position.length; opIx++) {
          const { operation_name: op } = position[opIx];
          if (op.toLowerCase().includes(searchOp)) {
            opIndexes[offset + opIx] = true;
            show = true;
          }
        }
      }
      offset += position.length;
      if (show) {
        posIxs[posIx] = true;
      }
    }

    // filter positions
    offset = 0;
    positions = positions
      .map((it, ix) => {
        let res;
        if (posIxs[ix]) {
          res = it.filter((_op, opIx) => opIndexes[offset + opIx]);
        } else {
          res = null;
        }
        offset += it.length;
        return res;
      })
      .filter((it) => it);

    // sort users by most available
    // meaning sort by: 1) status OK, 2) status BUFFER
    const availability = users.map(({ positions: [u, ...ops] }) => {
      return [
        u,
        Object.keys(opIndexes).reduce(
          (sums, ix) => {
            const status = ops[ix];
            // TODO what about status === COURSE_STATUS.NEVER_EXPIRE ?
            if (
              status === COURSE_STATUS.OK ||
              status === COURSE_STATUS.NEVER_EXPIRE
            ) {
              sums[0] += 1;
            } else if (status === COURSE_STATUS.BUFFER) {
              sums[1] += 1;
              // TODO why is the status not expired for the red exclamation mark?
            } else if (
              status === COURSE_STATUS.MISSING ||
              status === COURSE_STATUS.EXPIRED
            ) {
              sums[2] += 1;
            }
            return sums;
          },
          [0, 0, 0],
        ),
      ];
    });
    // careful: sorting in reverse order on purpose, so we don't have to reverse the array afterwards
    availability.sort(([_1, [ok1, buf1]], [_2, [ok2, buf2]]) => {
      if (ok1 === ok2) {
        if (buf1 === buf2) return 0;
        return buf1 > buf2 ? -1 : 1;
      }
      return ok1 > ok2 ? -1 : 1;
    });
    const order = availability.reduce(
      (res, [u, vals], ix) => ({ ...res, [u]: [ix, vals] }),
      {},
    );
    users = users
      .filter(({ positions: [u] }) => sum(order[u][1]) > 0)
      .sort((a, b) => {
        const ixa = order[a.positions[0]][0];
        const ixb = order[b.positions[0]][0];
        return ixa < ixb ? -1 : 1;
      });
  } else {
    opIndexes = null;
  }

  return { positions, users, opIndexes };
};

const PositionOverviewTable = ({
  positions: positions_ = [],
  users: users_ = [],
  crewId,
  onUserSelected = () => {},
  onOperationSelected,
  onPositionSelected,
  searchOp: searchOpVal,
  searchRef,
}) => {
  const [searchOp_, { cancel }] = useDebounce(searchOpVal, 400, {
    maxWait: 1500,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [details, setDetails] = useState(null);
  const [courses, setCourses] = useState(null);
  const [historyItem, setHistoryItem] = useState(null);

  useEffect(() => {
    (async () => {
      const courses = await getAllCourses(crewId);
      setCourses(courses);
    })();
  }, [crewId]);

  useEffect(() => {
    (async () => {
      if (details?.id) {
        const [certificates, boughtCourses] = await Promise.all([
          getUserCertificates(details.id, crewId),
          getUserBoughtCourses(details.id, crewId),
        ]);
        setDetails((d) => ({ ...d, certificates, boughtCourses }));
      }
    })();
  }, [details?.id, crewId]);

  // use debounced value only if the search element is active and the current input value is non-empty
  // otherwise we can just update the display immediately
  let searchOp;
  if (!searchOpVal || document.activeElement !== searchRef) {
    searchOp = searchOpVal;
    // no need for a delayed update as we'll already be using the up to date value
    setTimeout(cancel, 0);
  } else {
    searchOp = searchOp_;
  }

  const { users, positions, opIndexes } = useMemo(
    () => applyOpFilter({ searchOp, positions: positions_, users: users_ }),
    [searchOp, positions_, users_],
  );

  const statusClicked = (e, d) => {
    setAnchorEl(e.currentTarget);
    const prop = mapKeys(positions.flat()[d.op], (_v, k) => camelCase(k));
    setDetails({ ...d, prop });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDetails(null);
  };
  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table stickyHeader size="small" className={"crew-table"}>
          <TableHead>
            <TableRow>
              <StickyTableCell>&nbsp;</StickyTableCell>
              {positions.map((position) => (
                <StickyTableCell
                  key={position[0].position_name}
                  className={"subcat"}
                  colSpan={position.length}
                  onClick={() => onPositionSelected(position[0].position_name)}
                >
                  <span>{position[0].position_name}</span>
                </StickyTableCell>
              ))}
            </TableRow>
            <TableRow>
              <StickyTableCell className={"cell"} />
              {positions.map((position) =>
                position.map((op) => (
                  <StickyTableCell
                    key={`${op.category_id}-${op.position_id}-${op.operation_id}`}
                    align="left"
                    className={"rotate-cell cell"}
                    onClick={() => onOperationSelected(op.operation_name)}
                  >
                    <p className={"rotate"}>
                      <span className={"inner"} title={op.operation_name}>
                        {op.operation_name}
                      </span>
                    </p>
                  </StickyTableCell>
                )),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <StickyTableCell
                  style={{ whiteSpace: "nowrap" }}
                  className={"cell"}
                  title={user.positions[0]}
                  onClick={() => onUserSelected(user.positions[0])}
                >
                  {formatShortName(user.positions[0])}
                </StickyTableCell>
                {user.positions
                  .slice(1)
                  .filter((_op, ix) => !opIndexes || opIndexes[ix])
                  .map((data, ix) => (
                    <TableCell
                      key={ix}
                      align="center"
                      padding="none"
                      style={{ verticalAlign: "bottom" }}
                      className={"cell"}
                    >
                      <Button
                        icon={<CourseStatusIcon status={data} />}
                        onClick={(e) =>
                          statusClicked(e, {
                            id: user.id,
                            name: user.positions[0],
                            op: ix,
                          })
                        }
                      />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        className={"co-details"}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={"toolbar right"}>
          <Button icon={<CloseIcon />} size={"small"} onClick={handleClose} />
        </div>
        {(!!details?.certificates && (
          <>
            <CertificateActions.Provider
              value={{
                showHistory(certificate) {
                  setHistoryItem(certificate);
                },
                getBoughtCourses() {
                  return details.boughtCourses;
                },
              }}
            >
              <CurrentUserCrewContext.Provider
                value={{ userId: details.id, crewId: crewId }}
              >
                <NavigationLink
                  to={`/crew/${crewId}/members/${details.id}/operations`}
                >
                  {`${details.name} > Operations`}
                </NavigationLink>
                <Operations
                  certificates={details.certificates}
                  courses={courses}
                  properties={[details.prop]}
                  expand
                />
              </CurrentUserCrewContext.Provider>
            </CertificateActions.Provider>
            {historyItem ? (
              <CourseHistory
                userId={details.id}
                certificate={historyItem}
                userCertificates={details.certificates}
                courses={courses}
                onClose={() => setHistoryItem(null)}
                className={"co-details-history"}
              />
            ) : null}
          </>
        )) ||
          "Loading..."}
      </Popover>
    </>
  );
};

export default PositionOverviewTable;
