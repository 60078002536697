import { useState } from "react";
import Paper from "@mui/material/Paper";
import Confirm from "../../components/Confirm";
import Button from "../../components/Button";
import "./index.scss";
import { addSchedule, getEventScheduled } from "../../api/schedule";
import {
  Form,
  Row,
  InputForm,
  Validation,
  DateTimePickerCustom
} from "../../components/Form";

const validation = {
  location: Validation.required,
  scheduleTrainingStartDate: Validation.required,
  scheduleTrainingEndDate: Validation.required,
};

const ScheduleAdd = ({ match, history }) => {
  const [isModalShow, setIsModalShow] = useState(false);

  async function addScheduleForm(data) {
    if (data.scheduleTrainingStartDate > data.scheduleTrainingEndDate) {
      setIsModalShow(true);
    } else {
      data.courseTrainingId = match.params.trainingId;
      data.eventType = match.params.eventType;

      if (match.params.userId === 'all') {
        const usersAbleToPractical = await getEventScheduled(match.params.eventType, match.params.trainingId);
        data.userIds = usersAbleToPractical
          .filter(el => (!el.start_date && !el.end_date))
          .map(i => i.user_id);
      } else {
        data.userIds = [match.params.userId];
      }

      const result = await addSchedule(data);
      if (result.ok) {
        history.push(`/trainings/schedule/${match.params.eventType}/${match.params.trainingId}`);
      }
    }
  }

  function confirmModal() {
    setIsModalShow(false);
  }
  
  return (
    <>
      <h2>Add new slot</h2>
      <Paper
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }}
      >
        <Form onSubmit={addScheduleForm} validation={validation}>
          <Row>
            <InputForm label="Location*" name="location" />
          </Row>  
          <Row>
            <DateTimePickerCustom label="Start date" name="scheduleTrainingStartDate" />
          </Row>
          <Row>
            <DateTimePickerCustom label="End date" name="scheduleTrainingEndDate" />
          </Row>
          <Button type="submit" title="Add">Add</Button>
          <Button
            title="Go back"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Form>
      </Paper>
      {isModalShow && (
        <Confirm title="Check your date interval" notification={true} onConfirm={confirmModal}>
          <p>The end date is greater than the start date</p>
        </Confirm>
      )}
    </>
  );
};

export default ScheduleAdd;