import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TableComponent from "../../components/TableComponent";
import { getMoodleCourses, updateMoodleCourse } from "../../api/courses";

import MoodleCourseItem from "./MoodleCourseItem";

const MoodleCoursesTable = ({ match, history }) => {
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(1);
  const [courses, setCourses] = useState([]);

  function onEdit(id) {
    history.push(`${match.path}/edit/${id}`);
  }

  async function onVisibleChange(id, newVisible) {
    await updateMoodleCourse(id, { visible: newVisible });

    setRefresh(refresh * -1);
  }

  async function onShowChange(id, newShow) {
    await updateMoodleCourse(id, { showAtAllCourses: newShow });

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const newCourses = await getMoodleCourses();

      setCourses(newCourses);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Courses</h2>
      {courses.length ? (
        <TableComponent
          header={[
            { title: "ID", sort: "id", style: { width: "5%" } },
            { title: "Fullname", sort: "displayName", style: { width: "10%" } },
            { title: "Summary", sort: "summary", style: { width: "20%" } },
            { title: "Start date", sort: "startDate", style: { width: "10%" } },
            { title: "Visible", sort: "visible", style: { width: "5%" } },
            {
              title: "Show at all courses",
              sort: "showAtAllCourses",
              style: { width: "5%" },
            },
            {
              title: "Refreshers",
              sort: "refreshers",
              style: { width: "15%" },
            },
            {
              title: "Configured",
              sort: "configured",
              style: { width: "10%" },
            },
            { title: "Theme", sort: "courseCategory", style: { width: "10%" } },
            { title: "Action", style: { width: "10%" } },
          ]}
          items={courses}
          renderChildren={(course) => (
            <MoodleCourseItem
              key={course.id}
              onEdit={onEdit}
              onVisibleChange={onVisibleChange}
              onShowChange={onShowChange}
              course={course}
            />
          )}
          search={["displayName", "id"]}
          filter={{
            filterBy: "visible",
            controlLabel: t("show_inactive_courses"),
          }}
          pagination
        />
      ) : null}
    </>
  );
};

export default MoodleCoursesTable;
